<template>
	<div id="deploy">
		<index>
			<el-breadcrumb>
				<el-breadcrumb-item>教务</el-breadcrumb-item>
				<el-breadcrumb-item to="/paike_index">智能排课</el-breadcrumb-item>
				<el-breadcrumb-item><a style="color: #000000;">排课</a></el-breadcrumb-item>
			</el-breadcrumb>

		</index>
	</div>
</template>

<script>
import index from '@/components/common/index.vue';
export default {
	components: { index },
	data() {
		return {
			input1: '',
			info: [],
			id: '',
			title: '',
			classList: [],
			classIndex: 0,
			TecherList: [],
			job_id: '',
			subjectList: '',
			subjectIndex: 0,
			SubjectTecherList: [],
			SubjectTecherIndex: -1,
			TecherIndex: -1,
			attend_day: [],
			subList: [],
			property: [],
			propertyIndex: -1,
			importVisible: false,
			file: '',
			fileUrl: '',
			semester_id: '',
			subjectList2:[],
			value:''
		};
	},
	mounted() {
		this.id = this.$route.query.id;
		this.title = this.$route.query.title;
		this.semester_id = this.$route.query.semester_id;
		this.initData();
		this.getDisciplineList();
	},
	methods: {
		handleChange(e){
			console.log(this.value)
		},
		handleClose(done) {
			done();
		},
		closeimportVisible() {
			this.file = '';
		},
		getFile(e) {
			let myfile = this.$refs.filElem;
			let files = myfile.files;
			let file = files[0];
			var formData = new FormData();
			formData.append('file', file);
			this.$api.setting.uploadFile(formData).then(res => {
				if (res.data.code == 1) {
					this.fileUrl = res.data.data.url;
					if (this.$refs.filElem.files.length) {
						this.file = this.$refs.filElem.files[0];
					}
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		submitimport() {
			this.$api.setting
				.deployimport({
					id: this.id,
					file: this.fileUrl
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('导入成功');
						this.initData();
					} else {
						this.$message.error(res.data.msg);
					}
				});
		},
		exports6() {
			this.$api.setting
				.deployExport({
					id: this.id
				})
				.then(res => {
					if (res.data.code == 1) {
						this.$message.success('导出成功');
						location.href = res.data.data;
					}
				});
		},
		initData() {
			this.$api.paike.lsCourse({ curriculum_arrangement_id: this.id }).then(res => {
				if (res.data.code == 1) {
					this.attend_day = res.data.data.attend_day;
					this.info = res.data.data.data;
					// this.subjectList = res.data.data.config;
					// this.property = res.data.data.course_attribute;
				} else {
					this.$message.error(res.data.msg);
				}
			});
		},
		subTeacherTap(index) {
			this.SubjectTecherIndex = index;
		},
		propertyTap(index) {
			this.subjectIndex = -1;
			this.SubjectTecherIndex = -1;
			this.propertyIndex = index;
		},
		techerTap(index) {
			this.TecherIndex = index;
		},
		classTap(index) {
			this.classIndex = index;
			// this.getCourseTecher()
		},
		subjectTap(index) {
			this.subjectIndex = index;
			this.SubjectTecherIndex = -1;
			this.propertyIndex = -1;
			// this.getSubjectTecherList()
		},
		getDisciplineList() {
			this.$api.paike.getDisciplineList({ semester_id: this.semester_id }).then(res => {
				this.subjectList = res.data.data;
				let data = res.data.data;
				let arr = []
				for(let i in data){
					let children = [];
					for(let j in data[i].teacher){
						children.push({
							value:data[i].teacher[j].teacher_id,
							label:data[i].teacher[j].teacher_name
						})
					}
					arr.push({
						value:data[i].school_discipline_id,
						label:data[i].discipline_name,
						children:children
					})
				}
				this.subjectList2 = arr;
			});
		},
		// 设置教师进课表
		letin(i, j, k) {
			let obj = {};
			if (this.SubjectTecherIndex < 0 && this.propertyIndex < 0) return this.$message.error('请选择教师');
			if (this.SubjectTecherIndex != -1) {
				this.info[i].list[j].list[k].user_name = this.subjectList[this.subjectIndex].teacher[this.SubjectTecherIndex].teacher_name;
				this.info[i].list[j].list[k].school_discipline_id = this.subjectList[this.subjectIndex].school_discipline_id;
				this.info[i].list[j].list[k].school_discipline_name = this.subjectList[this.subjectIndex].discipline_name;
				this.info[i].list[j].list[k].user_id = this.subjectList[this.subjectIndex].teacher[this.SubjectTecherIndex].teacher_id;
				obj.school_discipline_name = this.subjectList[this.subjectIndex].discipline_name;
				obj.school_discipline_id = this.subjectList[this.subjectIndex].school_discipline_id;
				obj.user_id = this.subjectList[this.subjectIndex].teacher[this.SubjectTecherIndex].teacher_id;
				obj.user_name = this.subjectList[this.subjectIndex].teacher[this.SubjectTecherIndex].teacher_name;
			}

			obj.class_id = this.info[i].list[j].list[k].class_id;
			obj.class_name = this.info[i].list[j].list[k].class_name;
			obj.day_num = this.info[i].list[j].list[k].day_num;
			obj.attend_day = this.info[i].list[j].list[k].attend_day;
			obj.curriculum_arrangement_id = this.id;
			if(this.info[i].list[j].list[k].id){
				obj.id = this.info[i].list[j].list[k].id;
			}
			obj.semester_id = this.info[i].list[j].list[k].semester_id;
			obj.day = this.info[i].list[j].list[k].day;
			obj.school_campus_id = this.info[i].list[j].list[k].school_campus_id;
			this.subList.push(obj);
			setTimeout(() => {
				this.$forceUpdate();
			});
		},
		save(s = 0) {
			if(!this.subList.length){return this.$message.error('您没有编辑课表');}
			this.$api.paike.updateCourse({data:this.subList}).then(res => {
				if (res.data.code == 1) {
					this.$message.success('保存成功');
					if (s == 1) {
						// this.changeStatus()
					} else {
						this.initData();
						this.subList = [];
					}
				} else {
					this.$message.error(res.data.msg);
				}
			});
		}
	}
};
</script>

<style lang="scss">
#deploy {
	.caozuo {
		background-color: #ffffff;
		padding: 10px 20px;
		margin-top: 15px;
	}
	.suoding {
		background-color: #f5f6f8;
		border-radius: 2px;
		color: #757c7e;
		margin-left: 15px;
		padding: 5px;
		.suo {
			padding: 0 5px;
			border-right: 1px solid #eaebec;
		}
		.suo_muen {
			padding: 0 8px;
			i {
				font-size: 18px;
			}
		}
	}
	.table-head {
		height: 50px;
		background-color: #f6f8fb;
		border-bottom: 1px solid #f0f2f5;
		.class_name {
			font-weight: 700;
			height: 100%;
			border-right: 1px solid #f0f2f5;
			width: 4.85416667vw;
			min-width: 39.46666667px;
			color: #68b0fd;
			font-size: 0.729vw;
		}
		.week_name {
			height: 100%;
			border-right: 1px solid #f0f2f5;
			flex: 1;
			min-width: 64px;
			border-right: 1px solid #f0f2f5;
			color: #8b8b8d;
			font-size: 0.7291666666666666vw;
		}
	}
	.table-item {
		height: 80px;
		border-bottom: 1px solid #f0f2f5;
		.time_name {
			height: 100%;
			background-color: #f6f8fb;
			width: 4.85416667vw;
			min-width: 39.46666667px;
			border-right: 1px solid #f0f2f5;
			color: #8b8b8d;
			font-size: 0.7291666666666666vw;
		}
		.cell {
			position: relative;
			overflow-x: hidden;
			height: 100%;
			border-right: 1px solid #f0f2f5;
			width: 6.25vw;
			flex: 1;
			min-width: 64px;
			cursor: pointer;
			display: -webkit-box;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: center;
			-ms-flex-pack: center;
			justify-content: center;
			-webkit-box-align: center;
			-ms-flex-align: center;
			align-items: center;
			-webkit-box-orient: vertical;
			-webkit-box-direction: normal;
			-ms-flex-direction: column;
			flex-direction: column;
			transition: all 0.2s ease 0s;
			-moz-transition: all 0.2s ease 0s;
			-webkit-transition: all 0.2s ease 0s;
			-o-transition: all 0.2s ease 0s;
			-ms-transition: all 0.2s ease 0s;
			p {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				margin: 0;
				padding: 0;
				border: 0;
				text-align: center;
				font-size: 0.7291666666666666vw;
			}
			.cell_subject {
				color: #6f6f6f;
				margin-bottom: 0.26041667vw;
			}
			.cell_teacher {
				color: #a9b8c0;
			}
		}
		.cell:hover {
			background-color: #1890ff;
			.cell_teacher {
				color: #ffffff;
			}
			.cell_subject {
				color: #ffffff;
			}
		}
	}
	.right_content {
		padding: 0 0 0 20px;
		.controller_item {
			position: relative;
			width: 100%;
			height: 3.74583333vw;
			min-height: 37.33333333px;
			cursor: pointer;
			color: #3d3d3d;
			.iconfont {
				font-size: 1.4583vw;
				color: #bfbfbf;
				margin-bottom: 5px;
			}
		}
		.controller_item:hover {
			background-color: #eeeeee;
		}
		.right-item {
			background-color: #fafafa;
			height: 76vh;
			padding: 10px;
			.right-item-title {
				height: 4.0833vh;
				.line {
					width: 0.1283vw;
					height: 1.483vh;
					background-color: #1890ff;
					margin-right: 8px;
				}
				i {
					color: #bfbfbf;
					margin-left: 4px;
				}
			}
			.subject-list {
				display: flex;
				flex-wrap: wrap;
				.subject-item {
					padding: 5px 15px;
					margin-right: 10px;
					border: 1px solid #efefef;
					border-radius: 2px;
					color: #797979;
					margin-bottom: 10px;
					background-color: #ffffff;
					font-size: 0.625vw;
					cursor: pointer;
				}
				.active {
					color: #9c7cec;
				}
			}
			.teacher-list {
				width: 100%;
				overflow-x: hidden;
				overflow-y: auto;
				-ms-overflow-style: none;
				overflow: '-moz-scrollbars-none';
				scrollbar-width: none;
				-webkit-touch-callout: none;
				-webkit-user-select: none;
				-khtml-user-select: none;
				-moz-user-select: none;
				-ms-user-select: none;
				user-select: none;
				max-height: 13.02083333vw;
				.teacher-item {
					padding: 10px 0;
					cursor: pointer;
					.icon-yuandian1 {
						color: #1890ff;
						font-size: 20px;
						margin-right: 3px;
					}
					.teacher-name {
						margin-right: 15px;
					}
					.subject-name {
						color: #98a6b1;
					}
					.num {
						color: #6caffd;
					}
				}
				.teacher-item:hover {
					background-color: #eeeeee;
				}
				.active {
					background-color: #eeeeee;
				}
			}
		}
	}
}
</style>
